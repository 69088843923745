<template>
  <b-card no-body class="step__wrapper rounded-0 fix-height px-0 border-white">
    <b-row class="no-gutters step__container">
      <div
        v-for="(step, index) in stepList"
        :key="index"
        class="step-item-container"
        :class="{
          active: step.visited,
          clickable: isStepClickable(step)
        }"
        @click="isStepClickable(step) && goToStep(step.routeName)"
      >
        <b-row
          class="step-item no-gutters align-items-center justify-content-center h-100"
        >
          <img
            :src="
              require(`./../../assets/wizard-step-icons/${step.navIcon}.svg`)
            "
            class="step__icon mr-1"
            :class="{ 'active__step-icon': step.visited }"
          />
          <div class="mb-0 text-uppercase text-center subtitle__1">
            {{ step.navName }}
          </div>
        </b-row>
      </div>
    </b-row>
  </b-card>
</template>

<script>
export default {
  methods: {
    isStepClickable(step) {
      return step.visited && !step.active;
    },
    goToStep(routeName) {
      this.$router.push({ name: routeName });
    }
  },
  computed: {
    stepList() {
      return this.$store.getters["wizard/getNavSteps"];
    }
  }
};
</script>

<style scoped>
.step__container {
  position: relative;
  overflow: auto;
  flex-flow: nowrap;
}
.step__wrapper {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.step-item-container {
  color: var(--gray);
  width: 140px;
  min-width: 140px;
  height: 60px;
  position: relative;
  background: var(--gray-light);
  margin-right: 4px;
}

.step-item-container.active {
  color: white;
  background: var(--red);
}

.step-item-container:first-child::after {
  border-left-color: var(--red);
  z-index: -1;
}

.step-item-container::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 30px solid white;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  z-index: 1;
}

.step-item-container::before {
  content: "";
  position: absolute;
  right: -30px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 30px solid var(--gray-light);
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  z-index: 2;
}

.step-item-container.active::before {
  border-left: 30px solid var(--red);
}

.step-item {
  margin-right: -30px;
}

.step__icon {
  width: 1.2rem;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}

.active__step-icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg)
    brightness(102%) contrast(101%);
}
</style>
