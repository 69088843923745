<template>
  <div>
    <div class="wizard__container">
      <div class="sticky-top">
        <wizard-steps />
      </div>
      <div class="min-h-100 px-3 py-5">
        <router-view :key="$route.name" />
      </div>
    </div>
    <div class="sticky__bottom wizard__nav-container">
      <wizard-navigation />
    </div>
  </div>
</template>

<script>
import WizardSteps from "./../components/Wizard/WizardSteps";
import WizardNavigation from "./../components/Wizard/WizardNavigation";
export default {
  components: {
    WizardNavigation,
    WizardSteps
  },
  computed: {}
};
</script>

<style scoped>
.sticky__bottom {
  position: sticky;
  bottom: 0;
  z-index: 2;
}
.sticky-top {
  z-index: 2;
}
.wizard__container {
  padding-bottom: 100px;
  min-height: calc(100vh - 64px - 62px);
}
</style>
