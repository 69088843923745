<template>
  <b-card class="rounded-0">
    <b-row class="no-gutters align-items-center justify-content-center">
      <b-col class="col-12 col-sm-6 col-md-auto mb-1 mb-md-0 pr-1">
        <b-button
          :disabled="!hasPreviousStep"
          block
          :class="{ active__btn: hasPreviousStep }"
          class="mr-2 btn-width custom__btn-color text-uppercase border-0"
          @click="goToPreviousStep"
        >
          {{ $t("button.back") }}
        </b-button>
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-auto mb-1 mb-md-0 pr-1">
        <b-button
          class="btn-width mr-2 text-uppercase"
          variant="red"
          block
          :disabled="!isValid"
          @click="goToNextStep"
        >
          {{ $t("button.forward") }}
        </b-button>
      </b-col>
      <b-col class="col-12 col-md-auto mb-1 mb-md-0 pr-1">
        <button
          class="arrow__btn text-uppercase"
          :class="{ active__btn: isProjectReady }"
          :disabled="!isProjectReady"
          @click="openBuyProjectConfirmationModal"
        >
          {{ $t("button.launchAnalysis") }}
        </button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      isFeasibilityValid: false
    };
  },
  computed: {
    totalRespondents() {
      return this.$store.getters["audience/getNumberOfRespondents"] || "-";
    },
    totalPrice() {
      return 2000;
    },
    totalHourRange() {
      return `5 - 10 ${this.$t("hours")}`;
    },
    isValid() {
      return this.$store.getters["wizard/isValid"];
    },
    hasPreviousStep() {
      return this.$store.getters["wizard/getPreviousRouteName"];
    },
    isProjectReady() {
      return this.$store.getters["wizard/isProjectReady"];
    },
    projectName() {
      return this.$store.getters["project/getActiveProject"].name || null;
    }
  },
  methods: {
    goToPreviousStep() {
      this.$store.dispatch("wizard/goToPreviousStep");
    },
    goToNextStep() {
      this.$store.commit("loader/SET_LOADER", {});

      const activeStep = this.$store.getters["wizard/getActiveStep"];
      const lastStep = this.$store.getters["wizard/getLastActiveStep"];
      if (activeStep === lastStep) {
        const nextAvailableStep = this.$store.getters["wizard/getNextStep"];
        this.$store.commit("wizard/SET_LAST_ACTIVE_STEP", nextAvailableStep);
      }
      this.$store
        .dispatch("project/updateProjectByClientId")
        .then(() => {
          this.$store.dispatch("wizard/goToNextStep");
        })
        .catch(() => {
          this.$store.dispatch("loader/close");
        });
    },
    openBuyProjectConfirmationModal() {
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "dialog.confirm.buyProjectTitle",
        messageText: "dialog.confirm.buyProjectMsg",
        messageParams: { projectName: this.projectName },
        confirmBtnCallback: this.retrieveFeasibilityAndBuyProject
      });
    },
    retrieveFeasibilityAndBuyProject() {
      this.$store.commit("loader/SET_LOADER", {
        messageText: "dialog.loader.checkProject"
      });
      const STARTED = Date.now();
      const MAX_WAIT_SECONDS = 3 * 60;
      const INTERVAL_SECONDS = 15;
      const INTERVAL = setInterval(() => {
        if (Date.now() - STARTED > MAX_WAIT_SECONDS * 1000) {
          clearInterval(INTERVAL);
          this.$store.dispatch("loader/close");
          this.checkFeasibility();
        } else {
          this.retrieveProjectFeasibility(INTERVAL);
        }
      }, INTERVAL_SECONDS * 1000);

      this.retrieveProjectFeasibility(INTERVAL);
    },
    retrieveProjectFeasibility(interval) {
      this.$store
        .dispatch("project/retrieveProjectFeasibility")
        .then(feasibilityList => {
          this.setFeasibility(feasibilityList);
          if (this.isFeasibilityValid) {
            this.$store.dispatch("loader/close");
            clearInterval(interval);
            return this.buyProject();
          }
        })
        .catch(() => {
          this.$store.dispatch("loader/close");
          clearInterval(interval);
        });
    },
    buyProject() {
      this.$store.commit("loader/SET_LOADER", {});
      return this.$store
        .dispatch("project/buyProject")
        .then(() => {
          return this.$store.dispatch("project/completeProject");
        })
        .then(() => {
          this.$router.push({ name: "Dashboard" });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
    setFeasibility(feasibilityList) {
      if (feasibilityList.length === 0) {
        this.isFeasibilityValid = false;
        return;
      }
      const READY_STATUS = "READY";
      const findInvalidIndex = feasibilityList.findIndex(feasibilityInfo => {
        return (
          !feasibilityInfo.feasibility ||
          feasibilityInfo.status !== READY_STATUS
        );
      });

      this.isFeasibilityValid = findInvalidIndex === -1;
    },
    checkFeasibility() {
      if (!this.isFeasibilityValid) {
        this.$store.commit("error/SET_ERROR", {
          titleText: "dialog.error.projectFeasibility.title",
          messageText: "dialog.error.projectFeasibility.message"
        });

        if (this.$route.name !== "Review") {
          this.$router.push({
            name: "Review",
            params: {
              ...this.$route.params,
              is404: true
            }
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.btn-width {
  min-width: 120px;
  height: 40px;
}
.custom__btn-color {
  color: #6c757d;
  background-color: var(--gray-medium);
}
.custom__btn-color.active__btn {
  color: white;
  background: #343a40;
}

.arrow__btn {
  /* max-width: max-content; */
  padding: 8px;
  position: relative;
  border: 0;
  height: 40px;
  background: var(--gray-medium);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 0;
  color: #6c757d;
  outline: none;
  opacity: 0.65;
}
.arrow__btn::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 28.3px;
  height: 28.3px;
  right: -14px;
  bottom: 0;
  top: 6px;
  transform: rotate(45deg);
  background: var(--gray-medium);
  z-index: -1;
  border-radius: 1px;
}

.arrow__btn.active__btn {
  color: white !important;
  background: #343a40 !important;
  opacity: 1;
}

.arrow__btn.active__btn::before {
  background: #343a40;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .arrow__btn {
    width: 100%;
    max-width: calc(100% - 20px);
  }
}
</style>
